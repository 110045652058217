<template>
    <div>
        <tab name="1"></tab>
        <div v-loading="dataLoading">
            <div v-if="formData.auditStatus && formData.auditStatus == 1" class="page-group-box clearfix">
                <div v-if="formData.auditStatus == 1" class="fl">
                    <span class="icon el-icon-warning color-warning"></span> 
                    当前小站基本信息正在等待审核，请耐心等待审核结果
                </div>
            </div>
            <el-form
                :model="formData"
                :rules="rules"
                ref="formData"
                label-position="top"
                class="MT10"
                :disabled="formData.auditStatus && formData.auditStatus == 1"
            >
                <div class="page-group-box">
                    <div class="page-group-title">基本信息</div>
                    <template v-if="formData">
                        <el-row class="MT20" :gutter="24">
                            <el-col :xs="24" :xl="16">
                                <div>
                                    <span class="color-regular">PC端小站地址: </span> 
                                    <el-link :href="webSiteUrl.get(formData.userType)" target="_blank" type="primary">{{webSiteUrl.get(formData.userType)}}</el-link>
                                </div>
                                <div class="MT10">
                                    <span class="color-regular">WAP端小站地址: </span> 
                                    <el-link :href="wapSiteUrl.get(formData.userType)" target="_blank" type="primary">{{wapSiteUrl.get(formData.userType)}}</el-link>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row v-if="formData.userType == 3" class="MT10" :gutter="24">
                            <el-col :xs="24" :xl="16">
                                <el-form-item>
                                    <span slot="label">现场购票链接:</span>
                                    <div class="code-box clearfix">
                                        <vue-qr class="fl" :logoSrc="logo" :logoMargin="2" :text="`${sswap}/livehouse/buyticket/${formData.id}`" :size="800" :margin="0" :callback="getImage"></vue-qr>
                                        <div class="info">
                                            <el-link :href="`${sswap}/livehouse/buyticket/${formData.id}`" target="_blank" type="primary">{{sswap+'/livehouse/buyticket/'+formData.id}}</el-link>
                                            <div>用户扫码或访问该链接的时候，可以直接看到您场地当天最近的一场演出</div>
                                            <el-tag class="MT20" @click="downloadCode">点击下载二维码</el-tag>
                                        </div>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </template>

                    <el-row :class="formData.userType == 3 ? '' : 'MT10'" :gutter="24">
                        <el-col :xs="24" :sm="12" :xl="8">
                            <el-form-item prop="avatar" label="头像/Logo:">
                                <image-upload 
                                    :url.sync="formData.avatar" 
                                    :urlFull.sync="formData.avatar" 
                                    :crop="true"
                                    :width="800"
                                    :height="800"
                                    class="avatar"
                                    @onSuccess="handleAvatarSuccess"
                                >
                                </image-upload>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :xl="8">
                            <el-form-item prop="name" label="名称:">
                                <el-input v-model="formData.name" placeholder="请输入名称" size="medium" maxlength="40"></el-input>
                            </el-form-item>
                            <el-form-item prop="cityCode" label="所在城市:">
                                <el-cascader
                                    v-model="formData.cityCode"
                                    :options="cityList"
                                    filterable
                                    :props="{label:'name',children:'cityList',value:'code',emitPath:false}"
                                    size="medium"
                                    style="width:100%">
                                </el-cascader>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 艺人部分 -->
                    <template v-if="formData.userType == 2">
                        <el-row :gutter="24">
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item prop="type" label="艺人形式:">
                                    <el-select v-model="formData.type" placeholder="请选择艺人形式" size="medium">
                                        <el-option :value="1" label="个人"></el-option>
                                        <el-option :value="2" label="团体"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                            <el-form-item prop="memberCount" label="成员人数:">
                                    <el-input v-model="formData.memberCount" placeholder="请输入成员人数" size="medium" type="number"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item prop="birthday" label="组建年份:">
                                    <el-date-picker 
                                        v-model="formData.birthday" 
                                        type="year" 
                                        value-format="timestamp" 
                                        placeholder="选择年份" 
                                        size="medium" 
                                        :picker-options="pickerOptions"
                                    ></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item prop="styles" label="风格:">
                                    <el-cascader
                                        v-model="styles"
                                        :options="styleList"
                                        filterable
                                        :props="{multiple:true,label:'name',children:'tags',value:'id',emitPath:true,checkStrictly:true}"
                                        size="medium"
                                        style="width:100%"
                                        @change="selectStyles"
                                        >
                                        <template slot-scope="{ node, data }">
                                            <span>{{ data.name }}</span>
                                            <span v-if="!node.isLeaf"> ({{ data.tags.length }}) </span>
                                        </template>
                                    </el-cascader>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </template>

                    <!-- 场地部分 -->
                    <template v-if="formData.userType == 3">
                        <el-row :gutter="24">
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item prop="longitude" >
                                    <span slot="label">
                                        坐标地址:
                                        <el-button type="text" icon="el-icon-map-location" @click="$refs.selectAddress.show()">选择地址</el-button> 
                                        <small style="margin-left:10px" v-if="formData.longitude">(经度: {{formData.longitude}} 纬度: {{formData.latitude}})</small>
                                    </span>
                                    <el-input v-model="formData.address" placeholder="百度地图根据搜索结果返回的地址" size="medium" maxlength="100"></el-input>
                                </el-form-item> 
                            </el-col>  
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item prop="contact" label="咨询电话:">
                                    <el-input v-model="formData.contact" placeholder="请输入咨询电话号码" size="medium"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item prop="capacity" label="容纳人数:">
                                    <el-input v-model="formData.capacity" placeholder="请输入容纳人数" size="medium"  type="number"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item prop="freeTicketNum" label="赠票数量上限:">
                                    <el-input v-model="formData.freeTicketNum" placeholder="请输入赠票数量上限" size="medium"  type="number"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item prop="siteType" label="场地类型:">
                                    <el-select v-model="formData.siteType" placeholder="请选择场地类型" size="medium">
                                        <el-option v-for="item in siteTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </template>

                    <!-- 厂牌部分 -->
                    <template v-if="formData.userType == 5">
                        <el-row :gutter="24">
                            <el-col :xs="24" :xl="16">
                                <el-form-item prop="performerIds" label="旗下艺人:">
                                    <el-select 
                                        v-model="formData.performerIds" 
                                        filterable 
                                        remote
                                        multiple
                                        placeholder="请选择艺人"
                                        :remote-method="searchArtist"
                                        :loading="userLoading"
                                        size="medium">
                                        <el-option
                                            v-for="a in artistList"
                                            :key="a.id"
                                            :label="a.name"
                                            :value="a.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </template>

                    <el-row :gutter="24">
                        <el-col :xs="24" :xl="16">
                            <el-form-item prop="poster" label="小站背景图:">
                                <image-upload 
                                    :url.sync="formData.poster" 
                                    :urlFull.sync="formData.poster" 
                                    :crop="true"
                                    :width="1280"
                                    :height="900"
                                    class="poster"
                                >
                                </image-upload>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>

                <div class="page-group-box MT10">
                    <div class="page-group-title">小站简介</div>
                    <el-row :gutter="24">
                        <el-col :xs="24" :xl="16">
                            <el-form-item prop="desc" label=" ">
                                <el-input v-model="formData.desc" type="textarea" :rows="8" maxlength="10000" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>   
                </div>
            </el-form>
            <div class="page-group-box MT10">
                <el-button v-if="formData.auditStatus && formData.auditStatus == 1" type="info" disabled>审核中</el-button>
                <el-button v-else type="primary" @click="submitForm" :loading="btnLoading">提交审核</el-button>
            </div>
        </div>
        

        <select-address 
            ref="selectAddress" 
            :address.sync="formData.address" 
            :lng.sync="formData.longitude" 
            :lat.sync="formData.latitude">
        </select-address>
    </div>
</template>

<script>
import Tab from './components/tab'
import ImageUpload from '@/components/ImageUpload'
import {fetchAllTag,fetchAllCity,fetchUser,fetchSiteTagList} from '@/assets/js/commonApi'
import SelectAddress from '@/components/SelectAddressBaiduMap';

import VueQr from 'vue-qr'
import logo from '@/assets/img/showstart-logo.png';

const defaultFormData = {
    avatar:'',
    name:'',
    cityCode:'',
    poster:'',
    desc:'',
    //艺人部分
    type:1,
    memberCount:1,
    birthday:'',
    styles:[],
    tags:[],
    tagIds:[],
    //场地部分
    latitude:'',
    longitude:'',
    address:'',
    contact:'',
    capacity:'',
    freeTicketNum:'',
    //厂牌部分
    performerIds:[],
    siteType: ""
}
export default {
    components:{Tab,ImageUpload,SelectAddress,VueQr},
    data(){
        return{
            dataLoading: false,
            formData:{
                ...defaultFormData
            },
            rules:{
                avatar:[{required: true, message: '请上传头像/Logo'}],
                name:[{required: true, message: '请输入名称'}],
                cityCode:[{required: true, message: '请选择所在城市'}],
                memberCount:[{required: true, message: '请输入成员人数'}],
                styles:[{required: true, message: '请选择风格'}],
                longitude:[{required: true, message: '请通过百度地图选择坐标地址'}],
                capacity: [{ required: true, message: "请输入容纳人数" }],
                freeTicketNum:[{ required: true, message: "请输入赠票数量上限" }],
                siteType:[{required: true, message: '请选择场地类型'}],
            },
            ssweb:process.env.VUE_APP_SSWEB_URL,
            sswap:process.env.VUE_APP_SSWAP_URL,
            styles:[],
            styleList:[],//所有风格Tag集合
            cityList:[],//所有省份城市集合
            artistList:[], //艺人集合
            userLoading: false,
            btnLoading: false,
            logo:logo,
            codeImage:'',
            pickerOptions:{
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            siteTypeList:[],
        }
    },
    computed:{
        webSiteUrl(){
            return new Map([
                [2, this.ssweb+'/artist/'+this.formData.id],
                [3, this.ssweb+'/venue/'+this.formData.id],
                [5, this.ssweb+'/host/'+this.formData.id]
            ])
        },
        wapSiteUrl(){
            return new Map([
                [2, this.sswap+'/pages/site/artist/artist?id='+this.formData.id],
                [3, this.sswap+'/pages/site/site/site?id='+this.formData.id],
                [5, this.sswap+'/pages/site/brand/brand?id='+this.formData.id]
            ])
        }
    },
    mounted(){
        this.getData();
        this.getCityList();

        this.getStyleList();
    },
    methods:{
        getData(){
            this.dataLoading = true
            this.$request({
                url: "/station/baseInfo",
                // params: {userId:this.userId},
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.formData = {
                            ...defaultFormData,
                            ...result, 
                            cityCode: result.cityCode ? parseInt(result.cityCode) : 10,
                            birthday: result.birthday ? new Date(result.birthday).getTime() : ''
                        }
                        this.styles = [];
                        this.formData.tags.map(item => {
                            const _arry = [];
                            _arry.push(item.styleId);

                            this.formData.styles.push(item.styleId);
                            if(item.tagId){
                                this.formData.tags.push(item.tagId);
                                _arry.push(item.tagId);
                            }
                            
                            this.styles.push(_arry);
                        })
                        this.styles = Array.from(this.styles).sort((x,y) => x[0] - y[0]);
                        this.selectStyles();

                         //厂牌
                        if( result.userType == 5 ){
                            if(result.performers){
                                this.formData.performerIds = result.performers.map(item => item.id);
                                this.artistList = [...result.performers];
                            }
                        }

                        //场地
                        if( result.userType == 3 ){
                            this.getSiteType();
                        }

                    } else {
                        this.$message.error(msg || "获取信息失败");
                    }
                })
                .catch(() => {
                    this.$message.error("获取信息失败");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
        //获取所有风格
        async getStyleList(){
            const res = await fetchAllTag({}).catch(() => {});
            const{ state, result} = res.data;
            if( state == 1 ){
                this.styleList = result;
            }
        },
        //获取所有城市
        async getCityList(){
            const res = await fetchAllCity({}).catch(() => {});
            const{ state, result} = res.data;
            if( state == 1 ){
                this.cityList = result;
            }
        },
        //获取场地类型
        async getSiteType(){
            const res = await fetchSiteTagList({}).catch(() => {});
            const{ state, result} = res.data;
            if( state == 1 ){
                this.siteTypeList = result;
            }
        },
        //搜索艺人
        async searchArtist(query){
            if(query !== ""){
                this.userLoading = true;
                const res = await fetchUser({
                    queryValue:query,
                    userType:2,
                }).finally(() => {
                    this.userLoading = false;
                });
                
                const { state, result } = res.data;
                
                if (state == 1 && result) {
                    this.artistList = result;
                }
            }
        },
        //选取风格
        selectStyles(){
            // 风格&tag id组装
            let _styles = [], _tags = [];
            this.styles.forEach( item =>  {
                _styles.push( item[0] );
                if(item[1]){
                    _tags.push( item[1] ); 
                }
            })

            this.formData.styles = _styles ? [...new Set(_styles)] : [];
            this.formData.tags = _tags ? [...new Set(_tags)] : [];
            this.formData.tagIds = this.formData.styles.concat(this.formData.tags);
        },
        handleAvatarSuccess(){
            if(this.formData.avatar){
                this.$refs["formData"].clearValidate('avatar');
            }
        },
        submitForm(){
            // this.selectStyles();
            this.$refs["formData"].validate((valid) => {
                if (valid) {
                    this.$confirm('修改小站基本信息，需要审核通过才能生效，是否提交审核？', '提示', {
                        type: 'warning',
                        center: true,
                        closeOnClickModal: false
                    }).then( () => {
                        const _url = "/station/commit";
                        this.btnLoading = true;
                        
                        this.$request({
                            url: _url,
                            data: {...this.formData},
                            method: "post",
                        })
                            .then((res) => {
                                const { state, msg } = res.data;
                                if (state == 1) {
                                    this.$message.success('操作成功');
                                    this.getData();
                                } else {
                                    this.$message.error(msg || "操作失败,请重试");
                                }
                            })
                            .catch(() => {
                                this.$message.error("操作失败,请重试");
                            })
                            .finally(() => {
                                this.btnLoading = false;
                            });
                    }).catch( () => {})
                }else {
                    return false;
                }
            });
        },
        getImage(url){
            this.codeImage = url;
        },
        downloadCode(){
            const _data = this.$utils.base64ToBlob(this.codeImage);
            this.$utils.exportFile(_data,`${this.formData.name}的现场购票二维码.png`);
        },
    }
}
</script>


<style lang="scss" scoped>
.el-form{
    .avatar{
        ::v-deep .image{
            width: 160px;
            height: 160px;
        }
        ::v-deep .image-upload-content{
            height: 160px;
        }
        ::v-deep .el-image{
            border-radius: 100%;
            overflow: hidden;
            max-width: 80%;
            max-height: 80%;
        }
    }
    .poster{
        ::v-deep .image{
            width: 284px;
            // height: 160px;
        }
    }
}

.code-box{
    img{
        margin-right: 24px;
        width: 150px;
    }
    .info{
        overflow: hidden;
        .el-tag{
            cursor: pointer;
        }
    }
}
</style>